import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";



export default function Whatsthestory() {
  return (
    <Layout pageTitle="What's the Story?">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>What's the Story - USI Projects</title>
        <meta name="description" content="What's the Story? is an online and direct engagement campaign designed to reach out to East Belfast locals."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

       <section class="text-gray-700 body-font heading-container-whatsthestory">
       <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">What's the Story</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Portview Trade Center</p>
              </div>
          </section>
        </div>
      </section>





      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 class="text-6xl font-heading text-red-400">OVERVIEW</h3>
                <p
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl   ">
                  <bold>What’s the Story?</bold> is an online and direct engagement campaign designed to reach out East Belfast locals 
                  to ask them about the former Strand Spinning Mill and what they would like to see from the future redevelopment 
                  of Portview Trade Centre. We carried out engagement during COVID 19 restrictions over 3 months popping up in CS Lewis 
                  Square and Connswater Shopping Centre.  Meeting people directly provided us with valuable insights into the history and 
                  heritage of the former mill and helped us shape what Portview could now become in the future.
                </p>
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase  ">Fields of
                  Practice</p>
                <p
                  class="my-4 text-gray-500 leading-relaxed    mt-0">Communities<br/>
                  Resilience<br/>Public Space<br/>Culture<br/>Regeneration<br/>Tourism<br/>Economy<br/>Technology
                </p>
                <p class="text-2xl font-bold uppercase    mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed    mt-0">Engagement<br/></p>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div class="md:p-16 md:m-16  ">
        <video controls src="https://player.vimeo.com/progressive_redirect/playback/670732423/rendition/1080p/1080p.mp4?loc=external&signature=0897fb1811c371e22ed453d938820e1db4db61c3c24350697b2a67604686cbb8">
        </video>
      </div>

      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <section>
            <p class="text-xl md:text-2xl lg:text-3xl  md:pt-9 md:pl-20 md:pr-20 md:pb-0 md:mb-16 md:mr-20"> We met people who had worked in the mill and they told us their stories about what life was like to 
              work there and in East Belfast during 50’s, 60’s and 70’s.<br/></p>
            <div class="flex flex-wrap">
              <div class="w-full md:px-40 xl:w-6/12 py-4 xl:px-32 mb-4 md:mb-0">
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="text-xl md:text-2xl lg:text-3xl  md:pt-9 md:pl-0 md:pr-0 md:pb-0 md:mb-16 md:mr-10  ">
                  We learned about Doffers, Bogey Boys, Winders and Spinners and about why people went to work in The 
                  Strand Spinning Mill and what it meant for the local community. Most started work at 14- for some it was an 
                  opportunity gain an apprenticeship towards a more skilled job, for others it was a means to supporting their families.
                </p>
              </div>
              <div class="w-full xl:w-6/12 py-4 mb-4 md:mb-0">
                <img class="w-full xl:w-8/12 px-5 xl:px-0" src="../img/projects/portviewstoriesconnswater.webp" alt="Portview Stories"/>
              </div>
            </div>
            <p class="text-xl md:text-2xl lg:text-3xl  md:pt-9 md:pl-20 md:pr-20 md:pb-0 md:mb-16 md:mr-20">Our engagement led us to meet the Great Grand Daughter HR Richardson who grew the first banana in Ireland. Richardson 
              was the head gardener at Kinnedar Estate on Sydenham Avenue- incidentally the home of Sir Otto Jaffe who built the 
              Strand Spinning Mill. This led us to develop concepts around closed loop systems and agri tech as new fields for 
              employment and sustainability.</p>
          </section>
        </div>
      </section>



      <img data-scroll data-scroll-offset="200" data-scroll-speed="2" class="w-full md:pt-16 md:pb-16"
      src="../img/projects/spinning-memories.png" alt="Spinning Memories"></img>

<div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-red-400">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../reinvention" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
